import { Controller } from 'stimulus';
// import PSPDFKit from 'pspdfkit';
const pdfjsLib = require('pdfjs-dist/es5/build/pdf.js');
const pdfjsWorker = require('pdfjs-dist/es5/build/pdf.worker.entry');

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class extends Controller {
  static targets = ['docContainer'];

  connect() {
    // PSPDFKit

    // PSPDFKit.load({
    //   container: "#pspdfkit",
    //   document: this.docContainerTarget.dataset.fileUrl,
    //   licenseKey: process.env.PSPDFKIT_LICENSE_KEY,
    // })
    // .then(instance => {
    //   console.log("PSPDFKit loaded", instance);
    // })
    // .catch(error => {
    //   console.error(error.message);
    // });

    // PDF.js

    try {
      this.fileUrl = this.docContainerTarget.dataset.fileUrl;

      // console.log('pdf.js version: ' + pdfjsLib.version);

      this.pdfDoc = null;
      this.pageNum = 1;
      this.pageRendering = false;
      this.pageNumPending = null;
      this.scale = 1.5;
      // this.canvas = document.getElementById('pdf-viewer');
      // this.ctx = this.canvas.getContext('2d');

      this.documentViewer = document.getElementById(
        'document-viewer-multi-page'
      );

      pdfjsLib.getDocument(this.fileUrl).promise.then((pdf) => {
        this.pdf = pdf;
        // document.getElementById('page_count').textContent = pdf.numPages;
        for (let i = 1; i <= pdf.numPages; i++) {
          this.renderPage(i);
        }
        // this.renderPage(this.pageNum);
      });
      // document.getElementById('prev').addEventListener('click', this.onPrevPage.bind(this));
      // document.getElementById('next').addEventListener('click', this.onNextPage.bind(this));
    } catch (error) {
      console.log(error);
    }
  }

  renderPage(num) {
    this.pageRendering = true;
    // Using promise to fetch the page
    this.pdf.getPage(num).then((page) => {
      const viewport = page.getViewport({
        scale: this.scale,
      });

      // console.log(JSON.stringify(viewport, null, 2));

      const div = document.createElement('div');
      div.className = 'document-page';
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      div.appendChild(canvas);
      this.documentViewer.appendChild(div);

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };

      // this.canvas.height = viewport.height;
      // this.canvas.width = viewport.width

      // const renderContext = {
      //   canvasContext: this.ctx,
      //   viewport: viewport,
      // };page

      const renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          // New page rendering is pending
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      });
    });

    // Update page counters
    // document.getElementById('page_num').textContent = num;
  }

  /**
   * If another page rendering in progress, waits until the rendering is
   * finished. Otherwise, executes rendering immediately.
   */
  queueRenderPage(num) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  /**
   * Displays previous page.
   */
  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.queueRenderPage(this.pageNum);
  }

  /**
   * Displays next page.
   */
  onNextPage() {
    if (this.pageNum >= this.pdf.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }
}
