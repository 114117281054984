import { Controller } from 'stimulus';
import axios from 'axios';
import { template } from 'lodash';

export default class extends Controller {
  static targets = [
    // 'companyCardTemplate',
    'companiesList',
    'advisorId',
    'searchBox',
  ];

  connect() {
    // this.template = template(this.companyCardTemplateTarget.innerHTML);
    // this.url = `/api/v1/companies`;
    this.url = `/clients`
    this.params = { page: 1 };
    const advisorId = this.companiesListTarget.dataset.advisorId;
    if (advisorId) {
      this.params['advisor_id'] = advisorId;
    }
    // this.fetchCompanies();
  }

  submitSearch(e) {
    e.preventDefault();
    const query = this.searchBoxTarget.value.trim();
    if (query.length > 0 && this.params['q'] != query) {
      this.params['q'] = query;
      this.fetchCompanies();
    }
  }

  changeSearchInput(e) {
    if (e.which == 13) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length > 0 && this.params['q'] != query) {
        this.params['q'] = query;
        this.fetchCompanies();
      }
    } else if (e.which == 8) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length == 0) {
        this.params['q'] = null;
        this.fetchCompanies();
      }
    }
  }

  fetchCompanies() {
    let url = this.url;
    if (this.params['q'] && this.params['q'].length > 0) {
      url += `?q=${this.params['q']}`
    }
    if (this.params['advisor_id']) {
      url += `&advisor_id=${this.params['advisor_id']}`
    }
    window.location = url;
    // console.log('fetching with params: ', this.params);
    // axios.get(this.url, { params: this.params }).then((response) => {
    //   let htmlResult = '';
    //   console.log(response.data.length);
    //   response.data.map((result) => {
    //     htmlResult += this.template({
    //       id: result.id,
    //       on_site_address: result.location_address,
    //       business_phone: result.phone,
    //       on_site_po_box: result.location_po_box,
    //       contact_name: result.company_owner_name,
    //       on_site_suburb: result.location_suburb,
    //       on_site_postal_code: result.location_postal_code,
    //       phone: result.mobile,
    //       on_site_state: result.location_state,
    //       email: result.email,
    //       company_logo: result.company_logo,
    //       trading_name: result.trading_name,
    //       advisor_name: result.advisor_name,
    //       company_employees_count: result.company_employees_count,
    //       user_count: result.company_employees_count,
    //       need_attention: result.company_score.need_attention,
    //       review_required: result.company_score.review_required,
    //       all_good: result.company_score.all_good,
    //       not_applicable: result.company_score.not_applicable,
    //       actioned: result.company_score.actioned,
    //       assigned: result.company_score.assigned,
    //     });
    //   });
    //   this.companiesListTarget.innerHTML = htmlResult;
    // });
  }
}
