import React from 'react';

const Phone = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      x='0'
      y='0'
      enableBackground='new 0 0 15 15'
      version='1.1'
      viewBox='0 0 477.867 477.867'
      xmlSpace='preserve'
      fill={inputType == 'phone' ? '#374176' : '#6b6b6b'}
      viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"
    >
      <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
      <circle cx='153.6' cy='187.733' r='51.2'></circle>
    </svg>
  );
};

export default Phone;
