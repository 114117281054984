import React from 'react';

const SignaturePadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 35 35'
    >
      <path
        fill='#47506B'
        d='M19.95 25.122a3.02 3.02 0 01-1.547.828l-4.76.952a3.009 3.009 0 01-2.735-.828A3.02 3.02 0 0110.022 24l-2.098 1.787a2.138 2.138 0 01-2.298.316 2.132 2.132 0 01-1.251-1.955v-.862c0-.021 0-.078-.069-.117a.123.123 0 00-.135.003L0 25.77v6.24c0 .557.452 1.009 1.01 1.009h21.875a1.01 1.01 0 001.01-1.01V21.178l-3.945 3.944z'
      ></path>
      <path
        fill='#47506B'
        d='M1.01 9.461A1.01 1.01 0 000 10.471v12.92l3.108-1.936a2.155 2.155 0 013.286 1.83v.863c0 .026 0 .086.078.122a.12.12 0 00.143-.02l2.894-2.465a1.01 1.01 0 01.922-.205l.6-3.001a3.02 3.02 0 01.828-1.548l7.57-7.57H1.01zM34.704 4.18L32.8 2.278a1.01 1.01 0 00-1.427 0l-1.19 1.19-.238-.238a1.01 1.01 0 00-1.428 0l-3.094 3.093 5.236 5.235 3.093-3.093a1.01 1.01 0 000-1.428l-.238-.238 1.19-1.19a1.009 1.009 0 000-1.427zM23.996 7.75L13.287 18.46a1.01 1.01 0 00-.276.516l-.952 4.76a1.01 1.01 0 001.188 1.187l4.76-.952a1.01 1.01 0 00.515-.276l10.709-10.708-5.235-5.236z'
      ></path>
    </svg>
  );
};

export default SignaturePadIcon;
