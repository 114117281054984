import { Controller } from 'stimulus';
import axios from 'axios';
import { template } from 'lodash';
import ejs from 'ejs';

export default class extends Controller {
  static targets = [
    'commentCardTemplate',
    'commentList',
    'commentBox',
    'commentAttachmentTemplate',
    'commentAttachmentList',
    'commentAttachmentBox',
  ];

  connect() {
    $('.form-control-file').on(
      'change',
      this.submitCommentAttachment.bind(this)
    );
    this.template = ejs.compile(
      this.commentCardTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );
    this.template1 = template(this.commentAttachmentTemplateTarget.innerHTML);
    const companyDocumentId = this.commentListTarget.dataset.companyDocumentId;
    if (companyDocumentId) {
      this.url = `/api/v1/company_documents/${companyDocumentId}/comments`;
      this.fetchComments();
    }
  }

  submitCommentAttachment(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append(
      'comment_attachment[doc]',
      this.commentAttachmentBoxTarget.files[0]
    );
    axios.post(`/api/v1/comment_attachments`, formData).then((response) => {
      let htmlResult = this.commentAttachmentListTarget.innerHTML;
      htmlResult += this.template1({
        id: response.data.id,
        comment_attachment: response.data.doc_data.metadata.filename,
      });
      this.commentAttachmentListTarget.innerHTML = htmlResult;
      this.commentAttachmentBoxTarget.value = '';
    });
  }

  deleteCommentAttachment(e) {
    e.preventDefault();
    axios
      .delete(`/api/v1/comment_attachments/${e.target.id}`)
      .then((response) => {
        document.getElementById(e.target.id).parentElement.remove();
      });
  }

  submitComment(e) {
    e.preventDefault();
    const attachmentIds = [];
    Array.from(document.getElementsByClassName('close-btn')).forEach(
      (element) => {
        attachmentIds.push(element.id);
      }
    );
    axios
      .post(this.url, {
        content: this.commentBoxTarget.value,
        attachment_ids: attachmentIds,
      })
      .then((response) => {
        this.fetchComments();
      });
  }

  fetchComments() {
    axios.get(this.url).then((response) => {
      let htmlResult = '';
      response.data.map((result) => {
        htmlResult += this.template({
          id: result.id,
          avatar_url: result.avatar_url,
          user_name: result.user_name,
          commented_time: this.dateFormat(result.created_at),
          comment_content: result.content,
          comment_attachments: result.comment_attachments,
        });
      });
      this.commentListTarget.innerHTML = htmlResult;
      this.clearForm();
    });
  }

  commentAttachments(comment_attachments) {
    const div = document.createElement('div');
    comment_attachments.map((a) => {
      const p = document.createElement('p');
      p.innerText = a.name;
      div.append(p);
    });
    return div;
  }

  dateFormat(date) {
    const newDate = new Date(date);

    return (
      newDate.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }) +
      ' ' +
      newDate.toLocaleTimeString()
    );
  }

  clearForm() {
    document.getElementById('content').value = '';
    this.commentAttachmentListTarget.innerHTML = '';
  }
}
