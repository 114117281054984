import React, { useState, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignaturePad from 'react-signature-canvas';
import Draggable from 'react-draggable';
import {
  TrashIcon,
  DragIcon,
  SaveIcon,
  SignaturePadIcon,
} from '../../assets/svg-icons';
import { BASE_URL } from '../../../constants';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

const Signature = ({
  id,
  input,
  color,
  setChecked,
  onDelete,
  onSaveInput,
  currentUser,
  currentUserId,
  inputValues,
  setInputValues,
  editFields,
  onStopHandler,
  scale,
  formType,
}) => {
  const [userSignature, setUserSignature] = useState('');
  const [savedSignatures, setSavedSignatures] = useState([]);
  const [signFieldId, setSignFieldId] = useState('');
  const [signatureBgColor, setSignatureBgColor] = useState(color);
  const signatureRef = useRef(null);
  const [toolbar, setToolbar] = useState('none');
  const [signature, setSignature] = useState(null);
  const [signMethod, setSignMethod] = useState(
    savedSignatures ? 'choose' : 'draw'
  );
  const [redrawSignId, setRedrawSignId] = useState('');
  const [label, setLabel] = useState(
    (input.metadata && input.metadata.label) || 'Add Signature'
  );
  const [zIndex, setZIndex] = useState(1);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/signatures?user_id=${currentUserId}`).then((res) => {
      setSavedSignatures(res.data);
    });
    if (formType == "form") {
      setSignature(input.value)
    } else {
      axios
      .get(`${BASE_URL}/api/v1/signatures/${input.value}?user_id=${currentUserId}`)
      .then((res) => setSignature(res.data));
    }
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    setSignatureBgColor(color);
    ReactTooltip.rebuild();
  }, [color]);

  const MouseMove = () => {
    setSignatureBgColor(input.color ? '#f4ec32BB' : '#808080BB');
    currentUser === 'HeadOfficeOwner' && setZIndex(1000);
    setToolbar('block');
  };

  const MouseLeave = () => {
    setSignatureBgColor(input.color ? input.color : '#80808070');
    toolbar === 'block' &&
      setTimeout(() => {
        setToolbar('none');
        setZIndex(1);
      }, 3000);
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const trimSignature = async () => {
    const signImage = signatureRef.current.isEmpty()
      ? ''
      : signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    redrawSignId ? updateSignature(signImage) : saveSignature(signImage);
    setSignature(signImage);
    setUserSignature(signImage);
    setSignFieldId(input.id);
  };

  const uploadSignature = () => {
    saveSignature(signature);
    setUserSignature(signature);
    setSignFieldId(input.id);
  };

  const saveSignature = async (userSign) => {
    const blob = await fetch(userSign).then((r) => r.blob());
    const formData = new FormData();
    formData.append('signature[signature]', blob, 'image.png');
    formData.append('signature[user_id]', currentUserId);

    userSign &&
      axios.post(`${BASE_URL}/api/v1/signatures.json`, formData).then((res) => {
        setSavedSignatures(savedSignatures.concat([res.data]));
        res.status === 200 &&
          setInputValues(
            inputValues
              .filter((ele) => ele.id !== input.id)
              .concat([{ id: input.id, value: res.data.id }])
          );
      });
  };

  const updateSignature = async (userSign) => {
    const blob = await fetch(userSign).then((r) => r.blob());
    const formData = new FormData();
    formData.append('signature[signature]', blob, 'image.png');
    formData.append('signature[user_id]', currentUserId);

    userSign &&
      axios
        .put(`${BASE_URL}/api/v1/signatures/${redrawSignId}`, formData)
        .then((res) => {
          setSavedSignatures(
            savedSignatures
              .filter((ele) => ele.id !== redrawSignId)
              .concat([res.data])
          );
          res.status === 200 &&
            setInputValues(
              inputValues
                .filter((ele) => ele.id !== input.id)
                .concat([{ id: input.id, value: res.data.id }])
            );
          setRedrawSignId('');
        });
  };

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        style={{
          position: formType == "form" ? 'unset' : 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          zIndex: zIndex,
        }}
        onMouseMove={MouseMove}
        onMouseLeave={MouseLeave}
      >
        <div
          style={{
            position: formType == "form" ? 'unset' : 'relative',
          }}
        >
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 8,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <input
                    data-tip='Required'
                    style={{
                      margin: 5,
                    }}
                    type='checkbox'
                    checked={input.required}
                    onChange={() => setChecked(!input.required, input.id)}
                  />
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id, 12, label);
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            data-tip={label}
            className='signature-container'
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
              background: signatureBgColor,
            }}
          >
            <Popup
              trigger={
                signature ? (
                  <div 
                    style={{ 
                      height: formType == "form" ? '150px' : '100%',
                      width: '100%' 
                    }}
                  >
                    <img
                      style={{ objectFit: 'scale-down' }}
                      height= {formType == "form" ? '150px' : '100%'}
                      width='100%'
                      src={signature}
                    />
                  </div>
                ) : (
                  <button
                    className='btn'
                    style={{
                      height: '100%',
                      width: '100%',
                      fontSize: 10 * scale,
                      padding: 0,
                    }}
                  >
                    Sign Here
                  </button>
                )
              }
              disabled={editFields}
              modal
            >
              {(close, isOpen) => (
                <div className='sign-pop-up'>
                  {isOpen && setZIndex(1)}
                  <div
                    style={{
                      padding: '20px 0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SignaturePadIcon />
                    <span style={{ color: '#47506B', fontSize: 18 }}>
                      Add Signature
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '20px 0px',
                    }}
                  >
                    <button
                      style={{ width: '50%' }}
                      className={
                        signMethod === 'choose'
                          ? 'btn btn-primary shadow-none'
                          : 'btn btn-outline-primary shadow-none'
                      }
                      onClick={() => setSignMethod('choose')}
                    >
                      Signatures
                    </button>
                    <button
                      style={{ width: '50%' }}
                      className={
                        signMethod === 'upload'
                          ? 'btn btn-primary shadow-none'
                          : 'btn btn-outline-primary shadow-none'
                      }
                      onClick={() => setSignMethod('upload')}
                    >
                      Upload
                    </button>
                  </div>
                  {signMethod === 'draw' && (
                    <div
                      style={{
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: '#BDBDBD',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <SignaturePad
                        ref={signatureRef}
                        penColor='black'
                        canvasProps={{ width: 1000, height: 200 }}
                      />
                      <div
                        style={{ position: 'absolute', bottom: 0, right: 10 }}
                        onClick={() => clearSignature()}
                      >
                        <span>
                          <i
                            className='fa fa-refresh'
                            aria-hidden='true'
                            style={{ fontSize: 12 }}
                          ></i>
                          Reset
                        </span>
                      </div>
                    </div>
                  )}
                  {signMethod === 'choose' && (
                    <div
                      style={{
                        width: '100%',
                        height: 200,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {savedSignatures &&
                        savedSignatures.map((sign, index) => (
                          <div key={`signature${index}`}>
                            <div
                              key={sign.id}
                              style={{
                                height: 100,
                                width: 100,
                                borderWidth: 1,
                                borderColor: '#BDBDBD',
                                borderStyle: 'solid',
                                margin: 5,
                              }}
                              onClick={() => {
                                setSignature(sign.signature_url);
                                setSignFieldId(input.id);
                                setInputValues(
                                  inputValues
                                    .filter((ele) => ele.id !== input.id)
                                    .concat([{ id: input.id, value: sign.id }])
                                );
                                close();
                              }}
                            >
                              <img
                                style={{ objectFit: 'scale-down' }}
                                height='100%'
                                width='100%'
                                src={sign.signature_url}
                              />
                            </div>
                            <label
                              style={{
                                fontSize: 12,
                                marginLeft: 5,
                                cursor: 'pointer',
                                color: '#374176',
                                textAlign: 'center',
                              }}
                              onClick={() => {
                                setSignMethod('draw');
                                setRedrawSignId(sign.id);
                              }}
                            >
                              Redraw
                            </label>
                          </div>
                        ))}
                      <div
                        style={{
                          height: 100,
                          width: 100,
                          borderWidth: 1,
                          borderColor: '#BDBDBD',
                          borderStyle: 'dashed',
                          margin: 5,
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          setSignMethod('draw');
                        }}
                      >
                        <label
                          style={{
                            fontSize: 50,
                            marginBottom: 0,
                            cursor: 'pointer',
                          }}
                        >
                          +
                        </label>
                      </div>
                    </div>
                  )}
                  {signMethod === 'upload' && (
                    <div
                      style={{
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: '#BDBDBD',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <label
                        style={{
                          width: '100%',
                          height: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        {!signature && 'Click to browse'}
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) => {
                            setSignature(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                          hidden
                        />
                        {signature && (
                          <img
                            style={{
                              cursor: 'pointer',
                              objectFit: 'scale-down',
                            }}
                            height='100%'
                            src={signature}
                          />
                        )}
                      </label>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginTop: 10,
                    }}
                  >
                    <button
                      style={{ width: '33%', marginRight: 5 }}
                      className='btn btn-outline-primary'
                      onClick={() => close()}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ width: '33%', marginLeft: 5 }}
                      className='btn btn-primary'
                      onClick={() => {
                        signMethod === 'upload' && uploadSignature();
                        signMethod === 'draw' && trimSignature();
                        close();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </Popup>
            {input.color && (
              <input
                type='text'
                style={{
                  width: '100%',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'lightgray',
                  fontSize: 12,
                }}
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Signature;
