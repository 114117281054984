import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { TrashIcon, DragIcon, SaveIcon } from '../../assets/svg-icons';

const AcknowledgedInput = ({
  id,
  input,
  color,
  setChecked,
  onDelete,
  onSaveInput,
  currentUser,
  inputValues,
  setInputValues,
  editFields,
  onStopHandler,
  acknowledgeLabel,
  setAcknowledgeLabel,
  scale,
}) => {
  const [inputColor, setInputColor] = useState(color);
  const [value, setValue] = useState(input.value ? input.value : false);
  const [label, setLabel] = useState(
    (input.metadata && input.metadata.label) || 'acknowledge'
  );
  const [toolbar, setToolbar] = useState('none');
  const [zIndex, setZIndex] = useState(1);

  const MouseMove = () => {
    setInputColor(input.color ? '#f4ec32BB' : '#80808050');
    setToolbar('block');
    currentUser === 'HeadOfficeOwner' && setZIndex(1000);
  };

  const MouseOut = () => {
    setInputColor(input.color ? input.color : '#80808050');
    toolbar === 'block' &&
      setTimeout(() => {
        setToolbar('none');
        setZIndex(1);
      }, 3000);
  };

  useEffect(() => {
    setInputColor(color);
  }, [color]);

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        style={{
          position: 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          zIndex: zIndex,
        }}
        onMouseMove={MouseMove}
        onMouseOut={MouseOut}
      >
        <div style={{ position: 'relative' }}>
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 8,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <input
                    data-tip='Required'
                    style={{
                      margin: 5,
                    }}
                    type='checkbox'
                    checked={input.required}
                    onChange={() => setChecked(!input.required, input.id)}
                  />
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id);
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              style={{ height: 12 * scale, width: 12 * scale }}
              type='checkbox'
              checked={value}
              readOnly={editFields}
              onChange={() => {
                setValue(!value);
                setInputValues(
                  inputValues
                    .filter((ele) => ele.id !== input.id)
                    .concat([{ id: input.id, value: !value }])
                );
              }}
            />
            {input.id ? (
              <label
                style={{
                  width: '100%',
                  marginLeft: 5,
                  paddingLeft: 5,
                  fontSize: 12 * scale,
                  marginBottom: 0,
                  background: inputColor,
                }}
              >
                {input.metadata && input.metadata.label}
              </label>
            ) : (
              <input
                data-tip='Add Label'
                type='text'
                style={{
                  height: '100%',
                  width: '100%',
                  marginLeft: 5,
                  fontSize: 12 * scale,
                  background: inputColor,
                }}
                value={label}
                readOnly={editFields}
                onChange={(e) => {
                  setLabel(e.target.value);
                  setAcknowledgeLabel(e.target.value);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default AcknowledgedInput;
