import React from 'react';

const DateIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      x='0'
      y='0'
      enableBackground='new 0 0 15 15'
      version='1.1'
      viewBox='0 0 477.867 477.867'
      xmlSpace='preserve'
      fill={inputType == 'date' ? '#374176' : '#6b6b6b'}
    >
      <path d='M119.467 0C110.041 0 102.4 7.641 102.4 17.067V51.2h34.133V17.067C136.533 7.641 128.892 0 119.467 0zM358.4 0c-9.426 0-17.067 7.641-17.067 17.067V51.2h34.133V17.067C375.467 7.641 367.826 0 358.4 0zM426.667 51.2h-51.2v68.267c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067V51.2h-204.8v68.267c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067V51.2H51.2C22.923 51.2 0 74.123 0 102.4v324.267c0 28.277 22.923 51.2 51.2 51.2h375.467c28.277 0 51.2-22.923 51.2-51.2V102.4c0-28.277-22.923-51.2-51.2-51.2zm17.066 375.467c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V204.8h409.6v221.867z'></path>
      <path d='M136.533 238.933H102.4c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM256 238.933h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H256c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM375.467 238.933h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.067-17.067-17.067zM136.533 307.2H102.4c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM256 307.2h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H256c9.426 0 17.067-7.641 17.067-17.067S265.426 307.2 256 307.2zM375.467 307.2h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067-.001-9.426-7.642-17.067-17.067-17.067zM136.533 375.467H102.4c-9.426 0-17.067 7.641-17.067 17.067S92.974 409.6 102.4 409.6h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.066-17.067-17.066zM256 375.467h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H256c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM375.467 375.467h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.067-17.067-17.067z'></path>
    </svg>
  );
};

export default DateIcon;
