import { Controller } from 'stimulus';
import axios from 'axios';
import { template } from 'lodash';
import ejs from 'ejs';

export default class extends Controller {
  static targets = [
    'companyCountTemplate',
    'companiesCount',
    'companyDetailsTemplate',
    'companiesDetailList',
    'advisorId',
    'seeMoreClientsTemplate',
    'seeMoreClients',
  ];

  connect() {
    this.appliedFilter = '7_days';
    this.filters = {
      '7_days': {
        label: '7 Days',
        value: 7,
      },
      '15_days': {
        label: '15 Days',
        value: 15,
      },
      '30_days': {
        label: '30 Days',
        value: 30,
      },
    };
    this.companiesByAdvisorFilter = '';
    this.fetchCompanies(
      this.filters[this.appliedFilter].value,
      this.advisorIdTarget.dataset.advisorId
    );
    this.companyDetailsTemplate = ejs.compile(
      this.companyDetailsTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );

    this.requesting = false;
    this.template = template(this.companyCountTemplateTarget.innerHTML);
    this.companiesCountTarget.innerHTML = this.template({ companies_count: 0 });
  }

  applyFilter(e) {
    e.preventDefault();
    this.appliedFilter = e.target.dataset.filter;
    this.companiesByAdvisorFilter = e.target.dataset.advisorid;
    this.fetchCompanies(
      this.filters[this.appliedFilter].value,
      this.companiesByAdvisorFilter
    );

    const buttons = e.target.parentElement.getElementsByTagName('a');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }
    e.target.classList.add('active');
  }

  fetchCompanies(filter, advisorFilter) {
    const url = `/api/v1/recent_companies`;
    const params = {
      per_page: 10,
      page: 1,
      from_time: filter,
      advisor_id: advisorFilter,
    };
    this.requesting = true;
    axios.get(url, { params }).then((response) => {
      this.requesting = false;
      this.template = template(this.companyCountTemplateTarget.innerHTML);
      this.companiesCountTarget.innerHTML = this.template({
        companies_count: response.data.total,
      });

      var singleCompanyRow = null;
      this.companiesDetailListTarget.innerHTML = '';
      if (response.data.companies) {
        response.data.companies.map((result) => {
          var created_at = result.created_at.slice(0, 10);
          var formatted = created_at.split('-').reverse().join(' / ');
          singleCompanyRow = this.companyDetailsTemplate(
            {
              company_logo: result.company_logo,
              company_id: result.id,
              company_name: result.trading_name,
              joining_date: formatted,
              company_advisor_name: result.advisor_name,
            }
          );
          this.companiesDetailListTarget.innerHTML += singleCompanyRow;
        });
        this.seeMoreClientsTemplate = template(
          this.seeMoreClientsTemplateTarget.innerHTML
        );
        this.seeMoreClientsTarget.innerHTML = this.seeMoreClientsTemplate();
      }
    });
  }

  getOffsetDate(days) {
    const oneDayOffset = 24 * 60 * 60 * 1000;
    const today = new Date();
    const offsetTime = today.setTime(today.getTime() - oneDayOffset * days);
    return new Date(offsetTime);
  }
}
