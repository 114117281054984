import React, { useState, useEffect } from "react";

const DateInput = ({
  input,
  inputValues,
  setInputValues
  }) => {
  const [val, setVal] = useState(input.value? input.value.substr(0,10) : "");
  const handleOnChange = (value, input) => {
    setVal(value)
    setInputValues(inputValues.filter((ele) => ele.id !== input.id).concat([{ id: input.id, value: value}]))
  }

  return (
    <div className="group-form">
      <input className="form-control" type="date" value={val} onChange={(e) => handleOnChange(e.target.value, input)}/>
    </div>
  );
};

export default DateInput;