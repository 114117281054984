import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'docContainer',
    'docSubmissionId',
    'docSubmissionIdInput',
    'companyDocumentUrl',
    'companyDocumentName',
    'model',
  ];

  connect() {
    this.docStatusFormVisible = false;
    this.docDateFormVisible = false;
  }

  toggleDocStatusForum(e) {
    e.preventDefault();
    const element = e.target;
    console.log('in');
    if (!this.docStatusFormVisible) {
      element.closest('.doc-status').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'choose-status-form'
      )[0].style.display = 'block';
      element.closest('.choose-status-form');
      this.docStatusFormVisible = true;
    } else {
      this.docStatusFormVisible = false;
    }
  }

  setDueDateForum(e) {
    e.preventDefault();
    const element = e.target;
    if (!this.docDateFormVisible) {
      element.closest('.doc-date').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'choose-date-form'
      )[0].style.display = 'block';
      element.closest('.choose-date-form');
      this.docDateFormVisible = true;
    } else {
      this.docDateFormVisible = false;
    }
  }

  downloadPdf(e) {
    e.preventDefault();
    const element = e.target;
    const companyDocumentUrl = element.dataset.companydocumenturl;
    const companyDocumentName = element.dataset.companydocumentname;
    var link = document.createElement('a');
    link.download = companyDocumentName;
    link.target = '_blank';
    link.href = companyDocumentUrl;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(companyDocumentUrl);
    link.remove();
  }

  showMailerModal(e) {
    e.preventDefault();
    console.log('in');
    const element = e.target;
    const employeeAssignmentId = element.dataset.employeeassignmentid;
    document.getElementById(
      'employee_assignment_id'
    ).value = employeeAssignmentId;
  }
}
