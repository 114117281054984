import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import {
  TrashIcon,
  DragIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  SaveIcon,
} from '../../assets/svg-icons';

const NameInput = ({
  id,
  input,
  color,
  onDelete,
  onSaveInput,
  currentUser,
  onStopHandler,
  scale,
}) => {
  const [nameInputBgColor, setNameInputBgColor] = useState(color);
  const fontSize = (input.metadata && input.metadata.font_size) || 12;
  const [inputFontSize, setInputFontSize] = useState(fontSize);
  const [toolbar, setToolbar] = useState('none');
  const [zIndex, setZIndex] = useState(1);

  const MouseMove = () => {
    setNameInputBgColor(input.color ? '#f4ec32BB' : '#808080BB');
    setToolbar('block');
    currentUser === 'HeadOfficeOwner' && setZIndex(1000);
  };

  const MouseLeave = () => {
    setNameInputBgColor(input.color ? input.color : '#80808070');
    toolbar === 'block' &&
      setTimeout(() => {
        setToolbar('none');
        setZIndex(1);
      }, 3000);
  };

  const incrementFontSize = () => {
    setInputFontSize(inputFontSize + 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  const decrementFontSize = () => {
    setInputFontSize(inputFontSize - 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  useEffect(() => {
    setNameInputBgColor(color);
  }, [color]);

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        style={{
          position: 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          zIndex: zIndex,
        }}
        onMouseMove={MouseMove}
        onMouseLeave={MouseLeave}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 8,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <div
                    data-tip='Increase Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={incrementFontSize}
                  >
                    <ArrowUpIcon />
                  </div>
                )}
                {input.color && (
                  <div
                    data-tip='Decrease Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={decrementFontSize}
                  >
                    <ArrowDownIcon />
                  </div>
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id, inputFontSize);
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className='signature-container'
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
              background: nameInputBgColor,
              fontSize: inputFontSize * scale,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            Name
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default NameInput;
