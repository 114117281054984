import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../constants';
import { TextInput, CheckboxInput, TextArea, DateInput } from '../forms/inputs';
import { Signature } from '../../input-types';
import axios from 'axios';
import { ToastContainer } from 'react-toastr';

let container;

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default ({ docType, docName, docId, submissionId, currentUserId, currentUser }) => {
  const [formData, setFormData] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  useEffect(() => {
    let fields = [];
    formData.map((input, i) => {
      input.field_type === 'checkbox' &&
        fields.push({ id: input.id, value: input.value });
      input.field_type === 'textarea' &&
        fields.push({ id: input.id, value: input.value });
      input.field_type === 'text' &&
        fields.push({ id: input.id, value: input.value });
      input.field_type === 'date' &&
        fields.push({ id: input.id, value: input.value });
    });
    setInputValues(fields);
  }, [formData]);

  useEffect(() => {
    const fetchCompanyFormsSchema = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/company_documents/${docId}/doc_form_fields`)
        .then((res) => setFormColumns(res.data));
    };
    const fetchDocFormSubmissionFields = async () => {
      const url = `${BASE_URL}/api/v1/company_documents/${docId}/document_form_submissions/${submissionId}`;
      await axios.get(url).then((res) => setFormData(res.data.input_fields));
    };

    if (docType === 'CompanyForm') {
      fetchCompanyFormsSchema();
      fetchDocFormSubmissionFields();
    }
  }, []);

  const handleSave = () => {
    const url = `${BASE_URL}/api/v1/company_documents/${docId}/document_form_submissions/${submissionId}`;
    axios
      .put(url, { document_form_fields: inputValues })
      .then((res) => {
        container.success(`Form has been submitted successfully!`, ``, {
          closeButton: true,
        });
        window.location = `${BASE_URL}/company_documents/${res.data.uuid}`;
      })
      .catch((error) => {
        container.error(error.message, ``, {
          closeButton: true,
        });
      });
  };
  return (
    <div className='container form-container'>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className='toast-top-right'
      />
      {formData &&
        formData
        .sort((a, b) => a.position - b.position)
        .map((input, i) => (
          <div key={i} className='input-row-container'>
            {input.field_type === 'section' ? (
              <div className="section-heading-container">
                <div className="section-heading">
                  {input.field_name}
                </div>
              </div>
            ) : (
              <>
                <div className='input-label-container'>
                  <span className="name">{input.field_name}:</span>
                  <span className="description">{input.description}</span>
                </div>
                <div className='input-container'>
                  {input.field_type === 'text' ? (
                    <TextInput
                      input={input}
                      inputValues={inputValues}
                      setInputValues={setInputValues}
                    />
                  ) : input.field_type === 'textarea' ? (
                    <TextArea
                      input={input}
                      inputValues={inputValues}
                      setInputValues={setInputValues}
                    />
                  ) : input.field_type === 'date' ? (
                    <DateInput
                      input={input}
                      inputValues={inputValues}
                      setInputValues={setInputValues}
                    />
                  ) : input.field_type === 'checkbox' ? (
                    <CheckboxInput
                      input={input}
                      inputValues={inputValues}
                      setInputValues={setInputValues}
                    />
                  ) : input.field_type === 'signature' ? (
                    <Signature
                      input={input}
                      inputValues={inputValues}
                      setInputValues={setInputValues}
                      currentUserId={currentUserId}
                      currentUser={currentUser}
                      formType={"form"}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
        {
          docType !== "TemplateForm" ? (
            <div className='input-row-container justify-content-end p-4'>
            <button className='btn btn-primary' onClick={handleSave}>
              Save
            </button>
          </div>
          ) : null
        }
    </div>
  );
};
