import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {}

  goBack(e) {
    e.preventDefault();
    const linkTag = e.target.parentElement;
    const referrer = linkTag.dataset.referrer;
    this.fallback_url = linkTag.dataset.fallbackUrl;
    if (referrer && window.history.state) {
      window.history.back();
    } else {
      this.fallback();
    }
  }

  fallback() {
    window.history.pushState({}, '', this.fallback_url);
    window.history.go(-1);
  }
}