import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import {
  TrashIcon,
  DragIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  SaveIcon,
} from '../../assets/svg-icons';
import ReactTooltip from 'react-tooltip';
import { throttle } from 'lodash';

let lengthArray = [];
const TextArea = ({
  id,
  input,
  color,
  inputValues,
  setChecked,
  onDelete,
  onSaveInput,
  currentUser,
  setInputValues,
  editFields,
  onStopHandler,
  scale,
}) => {
  const inputRef = useRef(null);
  const [inputColor, setInputColor] = useState(color);
  const [inputCursor, setInputCursor] = useState('arrow');
  const [inputFocused, setInputFocused] = useState(false);
  const fontSize = (input.metadata && input.metadata.font_size) || 12;
  const [inputFontSize, setInputFontSize] = useState(fontSize);
  const [toolbar, setToolbar] = useState('none');
  const [val, setVal] = useState(input.value ? input.value : '');
  const [label, setLabel] = useState(
    (input.metadata && input.metadata.label) || 'Add Text'
  );
  const [zIndex, setZIndex] = useState(1);
  const [maxLength, setMaxLength] = useState();

  const setFontSize = () => {
    console.log(new Date().getTime())
    if (inputFontSize > 8) {
      setInputFontSize(inputFontSize - 0.3);
    }
    console.log(inputFontSize);
    lengthArray.push(val.length);
    // TODO: this logic needs to improve.
    if (inputFontSize <= 8){
      setMaxLength(val.length);
    }
  }

  const onScroll = useCallback(throttle(setFontSize, 1000), [inputFontSize]);

  const getUnique = (array) => {
    let uniqueArray = [];
    for(let i=0; i < array.length; i++){
      if(uniqueArray.indexOf(array[i]) === -1) {
          uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  const increaseFontSize = () => {
    let value = getUnique(lengthArray);
    lengthArray = value;
    if (value[value.length-1] > val.length){
      setInputFontSize(inputFontSize + 0.3);
      value.pop();
    }
  }

  const MouseOver = () => {
    setInputColor(input.color ? 'rgb(193 193 193 / 33%)' : 'rgb(193 193 193 / 22%)');
    if (inputFocused) {
      setInputCursor('text');
    } else {
      setInputCursor('pointer');
    }
  };

  const MouseOut = () => {
    setInputColor(input.color ? input.color : '#80808070');
    setInputCursor('default');
  };

  const onFocus = () => {
    setLabel('');
    setInputCursor('text');
    setInputFocused(true);
    ReactTooltip.hide(inputRef.current);
    currentUser === 'HeadOfficeOwner' && setZIndex(1000);
    setToolbar('block');
  };

  const onBlur = () => {
    setLabel(
      (input.metadata && input.metadata.label) || 'Add Text'
    );
    setInputCursor('default');
    setInputFocused(false);
    ReactTooltip.hide(inputRef.current);
    setTimeout(() => setToolbar('none'), 200);
    setZIndex(1);
  };

  const onChange = (e) => {
    setVal(e.target.value);
    increaseFontSize();
    setInputValues(
      inputValues
        .filter((ele) => ele.id !== input.id)
        .concat([{ id: input.id, value: e.target.value, font_size: inputFontSize }])
    );
  }

  const incrementFontSize = () => {
    setInputFontSize(inputFontSize + 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  const decrementFontSize = () => {
    setInputFontSize(inputFontSize - 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  useEffect(() => {
    setInputColor(color);
    ReactTooltip.rebuild();
  }, [color, label]);

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        style={{
          position: 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          zIndex: zIndex,
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 8,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <div
                    data-tip='Increase Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={incrementFontSize}
                  >
                    <ArrowUpIcon />
                  </div>
                )}
                {input.color && (
                  <div
                    data-tip='Decrease Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={decrementFontSize}
                  >
                    <ArrowDownIcon />
                  </div>
                )}
                {input.color && (
                  <input
                    data-tip='Required'
                    style={{
                      margin: 5,
                    }}
                    type='checkbox'
                    checked={input.required}
                    onChange={() => setChecked(!input.required, input.id)}
                  />
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id, inputFontSize, label);
                      setToolbar('none');
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
            }}
          >
            {/* <div
              contentEditable="true"
              id="custom-textarea"
              style={{
                height: "100%",
                width: "100%",
                overflow: 'scroll',
                fontSize: inputFontSize * scale,
                background: inputColor,
                resize: 'none',
                outline: 'none',
                borderRadius: 0,
                cursor: inputCursor
              }}
              onScroll={onScroll}
              maxLength={maxLength}
              onKeyUp={(e) => {
                if (e.target.textContent.length > maxLength) {
                  return
                }
                setVal(e.target.innerHTML);
                increaseFontSize();
                setInputValues(
                  inputValues
                    .filter((ele) => ele.id !== input.id)
                    .concat([{ id: input.id, value: e.target.innerHTML, font_size: inputFontSize }])
                );
              }}
              onPaste={(e) => {
                e.preventDefault();
                let text = e.clipboardData.getData("text/plain");
                let temp = document.createElement("div");
                temp.innerHTML = text;
                document.execCommand("insertHTML", false, temp.textContent);
                temp.remove();
                setVal(e.target.innerHTML);
                increaseFontSize();
                setInputValues(
                  inputValues
                    .filter((ele) => ele.id !== input.id)
                    .concat([{ id: input.id, value: e.target.innerHTML, font_size: inputFontSize }])
                );
              }}
            > 
            </div>*/}
            <textarea
              data-tip={label}
              required={input.checked}
              autoFocus
              className='text-input'
              ref={inputRef}
              style={{
                fontSize: inputFontSize * scale,
                background: inputColor,
                resize: 'none',
                outline: 'none',
                borderRadius: 0,
                cursor: inputCursor
              }}
              value={val}
              readOnly={editFields}
              maxLength={maxLength}
              onScroll={onScroll}
              onChange={onChange}
              onPaste={onChange}
              onMouseOver={MouseOver}
              onMouseOut={MouseOut}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {input.color && (
              <input
                type='text'
                style={{
                  width: '100%',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'lightgray',
                  fontSize: 12,
                }}
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default TextArea;
