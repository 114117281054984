import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Draggable from 'react-draggable';
import {
  TrashIcon,
  DragIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  SaveIcon,
} from '../../assets/svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';

const DateInput = ({
  id,
  input,
  color,
  setChecked,
  onDelete,
  onSaveInput,
  currentUser,
  inputValues,
  setInputValues,
  editFields,
  onStopHandler,
  scale,
}) => {
  const [inputColor, setInputColor] = useState(color);
  const [date, setDate] = useState(
    input.value ? new Date(input.value) : ''
  );
  const fontSize = (input.metadata && input.metadata.font_size) || 12;
  const [inputFontSize, setInputFontSize] = useState(fontSize);
  const [toolbar, setToolbar] = useState('none');
  const [label, setLabel] = useState(
    (input.metadata && input.metadata.label) || 'Select Date'
  );
  const [zIndex, setZIndex] = useState(1);

  const MouseMove = () => {
    setInputColor(input.color ? '#f4ec32BB' : '#808080BB');
    currentUser === 'HeadOfficeOwner' && setZIndex(1000);
    setToolbar('block');
  };

  const MouseLeave = () => {
    setInputColor(input.color ? input.color : '#80808070');
    toolbar === 'block' &&
      setTimeout(() => {
        setToolbar('none');
        currentUser === 'HeadOfficeOwner' && setZIndex(1);
      }, 3000);
  };

  const incrementFontSize = () => {
    setInputFontSize(inputFontSize + 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  const decrementFontSize = () => {
    setInputFontSize(inputFontSize - 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  useEffect(() => {
    setInputColor(color);
    ReactTooltip.rebuild();
  }, [color, label]);

  const CustomInput = ({ value, onClick }) => (
    <div
      className='date-input'
      style={{ background: inputColor, fontSize: inputFontSize * scale }}
      onClick={() => {
        setZIndex(1000);
        onClick();
      }}
    >
      <span>{value}</span>
    </div>
  );

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        style={{
          position: 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          zIndex: zIndex,
        }}
        onMouseMove={MouseMove}
        onMouseLeave={MouseLeave}
      >
        <div style={{ position: 'relative' }}>
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: 8,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <div
                    data-tip='Increase Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={incrementFontSize}
                  >
                    <ArrowUpIcon />
                  </div>
                )}
                {input.color && (
                  <div
                    data-tip='Decrease Font Size'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={decrementFontSize}
                  >
                    <ArrowDownIcon />
                  </div>
                )}
                {input.color && (
                  <input
                    data-tip='Required'
                    style={{
                      margin: 5,
                    }}
                    type='checkbox'
                    checked={input.required}
                    onChange={() => setChecked(!input.required, input.id)}
                  />
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5,
                      marginBottom: 8,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id, inputFontSize, label);
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            data-tip={label}
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
            }}
          >
            <DatePicker
              selected={date}
              onChange={(date) => {
                setDate(date);
                setInputValues(
                  inputValues
                    .filter((ele) => ele.id !== input.id)
                    .concat([{ id: input.id, value: date }])
                );
              }}
              readOnly={editFields}
              peekNextMonth
              dateFormat='dd/MM/yyyy'
              customInput={<CustomInput />}
            />
          </div>
          {input.color && (
            <input
              type='text'
              style={{
                width: input.width,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'lightgray',
                fontSize: 12,
              }}
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default DateInput;
