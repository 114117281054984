import React, { useEffect, useState } from 'react';
import { FormTemplate } from './forms';
import { BASE_URL } from '../../../constants';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default (props) => {
  const { docType, docName, docId, currentUser, currentUserId } = props;
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const fetchTemplateFormsFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/doc_templates/${docId}/doc_form_fields`)
        .then((res) => setFormData(res.data));
    };
    const fetchCompanyFormsFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/company_documents/${docId}/doc_form_fields`)
        .then((res) => setFormData(res.data));
    };
    const fetchDocSubmissionFormsFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/doc_submissions/${docId}/doc_form_fields`)
        .then((res) => setFormData(res.data));
    };
    docType === 'TemplateForm' && fetchTemplateFormsFields();
    docType === 'CompanyForm' && fetchCompanyFormsFields();
    docType === 'DocSubmissionForm' && fetchDocSubmissionFormsFields();
  }, []);

  return (
    <div className='container-fluid'>
      <FormTemplate
        {...{ formData, docType, docName, docId, currentUser, currentUserId }}
      />
    </div>
  );
};
