import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import {
  TrashIcon,
  DragIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  SaveIcon,
} from '../../assets/svg-icons';
import ReactTooltip from 'react-tooltip';

let lenghts = [];
const TextInput = ({
  id,
  input,
  color,
  inputValues,
  setChecked,
  onDelete,
  onSaveInput,
  currentUser,
  setInputValues,
  editFields,
  onStopHandler,
  scale,
}) => {
  const [inputColor, setInputColor] = useState(color);
  const fontSize = (input.metadata && input.metadata.font_size) || 12;
  const [inputFontSize, setInputFontSize] = useState(fontSize);
  const [toolbar, setToolbar] = useState('none');
  const [val, setVal] = useState(input.value ? input.value : '');
  const [label, setLabel] = useState(
    (input.metadata && input.metadata.label) || 'Add Text'
  );
  const [zIndex, setZIndex] = useState(1);
  const [maxLength, setMaxLength] = useState();

  const setFontSize = () => {
    setInputFontSize(inputFontSize - 0.5);
    lenghts.push(val.length);
    if (inputFontSize < 8){
      setMaxLength(val.length);
    }
  }

  const getUnique = (array) => {
    let uniqueArray = [];
    for(let i=0; i < array.length; i++){
      if(uniqueArray.indexOf(array[i]) === -1) {
          uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  const increaseFontSize = () => {
    let value = getUnique(lenghts);
    lenghts = value;
    if (value[value.length-1] > val.length){
      setInputFontSize(inputFontSize + 1);
      value.pop();
    }
  }

  const MouseOver = () => {
    setInputColor(input.color ? '#f4ec32BB' : '#808080BB');
  };

  const MouseOut = () => {
    setInputColor(input.color ? input.color : '#80808070');
  };

  const incrementFontSize = () => {
    setInputFontSize(inputFontSize + 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  const decrementFontSize = () => {
    setInputFontSize(inputFontSize - 1);
    setTimeout(() => setToolbar('block'), 200);
  };

  useEffect(() => {
    setInputColor(color);
    ReactTooltip.rebuild();
  }, [color, label]);

  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      handle='.handle'
      bounds='parent'
      onStop={(e, d) => onStopHandler(input, d.x, d.y, id)}
    >
      <div
        id={id}
        style={{
          position: 'absolute',
          top: input.color ? input.y_point : input.y_point * scale,
          left: input.color ? input.x_point : input.x_point * scale,
          lineHeight: 0,
          zIndex: zIndex,
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {currentUser === 'HeadOfficeOwner' && (
            <div className='toolbar' style={{ display: toolbar, zIndex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {input.color && (
                  <div
                    data-tip='Moveable'
                    className='handle'
                    style={{
                      cursor: 'move',
                      margin: 5,
                      height: 18,
                      width: 18,
                    }}
                  >
                    <DragIcon />
                  </div>
                )}
                <div
                  data-tip='Delete'
                  style={{
                    cursor: 'pointer',
                    margin: 5,
                    height: 18,
                    width: 18,
                  }}
                  onClick={() => onDelete()}
                >
                  <TrashIcon />
                </div>
                {input.color && (
                  <div
                    data-tip='Increase Font Size'
                    style={{
                      cursor: 'pointer',
                      margin: 5,
                      height: 18,
                      width: 18,
                    }}
                    onClick={incrementFontSize}
                  >
                    <ArrowUpIcon />
                  </div>
                )}
                {input.color && (
                  <div
                    data-tip='Decrease Font Size'
                    style={{
                      cursor: 'pointer',
                      margin: 5,
                      height: 18,
                      width: 18,
                    }}
                    onClick={decrementFontSize}
                  >
                    <ArrowDownIcon />
                  </div>
                )}
                {input.color && (
                  <input
                    data-tip='Required'
                    style={{
                      margin: 5,
                    }}
                    type='checkbox'
                    checked={input.required}
                    onChange={() => setChecked(!input.required, input.id)}
                  />
                )}
                {input.color && (
                  <div
                    data-tip='Save'
                    style={{
                      cursor: 'pointer',
                      margin: 5,
                      height: 18,
                      width: 18,
                    }}
                    onClick={() => {
                      onSaveInput(input.id, inputFontSize, label);
                      setToolbar('none');
                    }}
                  >
                    <SaveIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              height: input.color ? input.height : input.height * scale,
              width: input.color ? input.width : input.width * scale,
            }}
          >
            <input
              data-tip={label}
              required={input.checked}
              autoFocus
              type='text'
              className='text-input'
              style={{
                fontSize: inputFontSize * scale,
                background: inputColor,
                outline: 'none',
                borderRadius: 0
              }}
              value={val}
              readOnly={editFields}
              maxLength={maxLength}
              onScroll={(e) => setFontSize()}
              onChange={(e) => {
                setVal(e.target.value);
                increaseFontSize();
                setInputValues(
                  inputValues
                    .filter((ele) => ele.id !== input.id)
                    .concat([{ id: input.id, value: e.target.value, font_size: inputFontSize }])
                );
              }}
              onMouseOver={MouseOver}
              onMouseOut={MouseOut}
              onFocus={() => {
                setToolbar('block');
                currentUser === 'HeadOfficeOwner' && setZIndex(1000);
              }}
              onBlur={() => {
                setTimeout(() => setToolbar('none'), 200);
                setZIndex(1);
              }}
            />
            {input.color && (
              <input
                type='text'
                style={{
                  width: '100%',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'lightgray',
                  fontSize: 12,
                }}
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default TextInput;
