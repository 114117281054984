import React from 'react';

const SignatureIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      x='0'
      y='0'
      enableBackground='new 0 0 15 15'
      version='1.1'
      viewBox='0 0 25.588 25.588'
      xmlSpace='preserve'
      fill={inputType == 'signature' ? '#374176' : '#6b6b6b'}
    >
      <path d='M18.724 9.903l3.855 1.416-3.206 8.729c-.3.821-1.927 3.39-3.06 3.914l-.275.75a.472.472 0 01-.603.28.47.47 0 01-.279-.604l.26-.709c-.575-1.117-.146-4.361.106-5.047l3.202-8.729zM24.303.667c-1.06-.388-2.301.414-2.656 1.383l-2.322 6.326 3.854 1.414 2.319-6.325c.292-.792-.133-2.409-1.195-2.798zm-6.975 8.909a.942.942 0 001.209-.555l2.45-6.608a.942.942 0 00-1.764-.653l-2.45 6.608a.94.94 0 00.555 1.208zm-3.944 12.391c-.253-.239-.568-.537-1.078-.764-.42-.187-.829-.196-1.128-.203l-.103-.002c-.187-.512-.566-.834-1.135-.96-.753-.159-1.354.196-1.771.47.037-.21.098-.46.143-.64.144-.58.292-1.18.182-1.742a1.003 1.003 0 00-.914-.806c-1.165-.065-2.117.562-2.956 1.129-.881.595-1.446.95-2.008.749-.686-.244-.755-2.101-.425-3.755.295-1.49.844-4.264 2.251-5.524.474-.424 1.16-.883 1.724-.66.663.26 1.211 1.352 1.333 2.653a.996.996 0 001.089.902.999.999 0 00.902-1.089c-.198-2.12-1.192-3.778-2.593-4.329-.839-.326-2.173-.414-3.79 1.033-1.759 1.575-2.409 4.246-2.88 6.625-.236 1.188-.811 5.13 1.717 6.029 1.54.549 2.791-.298 3.796-.976.184-.124.365-.246.541-.355-.167.725-.271 1.501.167 2.155.653.982 1.576 1.089 2.742.321.045-.029.097-.063.146-.097.108.226.299.475.646.645.42.206.84.216 1.146.224.131.003.31.007.364.031.188.083.299.185.515.389.162.153.333.312.55.476a1 1 0 001.198-1.601c-.145-.112-.26-.223-.371-.328z'></path>
    </svg>
  );
};

export default SignatureIcon;
