import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['allTab'];

  connect() {
    const url = document.location.toString();
    const URL = unescape(url);
    if (URL.match('#')) {
      $('.nav-tabs a[href="#' + URL.split('#')[1] + '"]').tab('show');
    }
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
      window.location.hash = e.target.hash;
    });
  }
}
