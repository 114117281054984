import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['isHeadOffice', 'chooseHeadOffice', 'documentList'];

  connect() {
    this.chooseHeadOfficeTarget.style.display = 'none';
  }

  toggleHeadOffice() {
    if (this.isHeadOfficeTarget.checked) {
      this.chooseHeadOfficeTarget.style.display = 'none';
    } else {
      this.chooseHeadOfficeTarget.style.display = 'block';
    }
  }
  toggleFormNext() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step3.style.display = 'block';
      circle3.style.borderColor = '#CA2318';
      circle3.style.backgroundColor = '#CA2318';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
    }  else {
      step1.style.display = 'none';
      step2.style.display = 'block';
      circle2.style.borderColor = '#CA2318';
      circle2.style.backgroundColor = '#CA2318';
    }
  }

  toggleFormPrevious() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step1.style.display = 'block';
      circle2.style.borderColor = '#A4A7B1';
      circle2.style.backgroundColor = '#A4A7B1';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
      step2.style.display = 'block';
      circle3.style.borderColor = '#A4A7B1';
      circle3.style.backgroundColor = '#A4A7B1';
    }
  }
}
