import React, { useState } from 'react';

const CheckboxInput = ({ input, inputValues, setInputValues }) => {
  const [val, setVal] = useState(input.value ? input.value : false);

  const handleOnChange = (value, input) => {
    setVal(value);
    setInputValues(
      inputValues
        .filter((ele) => ele.id !== input.id)
        .concat([{ id: input.id, value: value }])
    );
  };

  return (
    <div className='form-check'>
      <input
        className='form-check-input'
        type='checkbox'
        value={val}
        onChange={(e) => handleOnChange(!val, input)}
      />
    </div>
  );
};

export default CheckboxInput;
