import React from 'react';

const TextAreaIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 512 512'
      fill={inputType == 'textarea' ? '#374176' : '#6b6b6b'}
    >
      <path d='M353.681 217.681h60c8.284 0 15-6.716 15-15V95c0-8.284-6.716-15-15-15H98.319c-8.284 0-15 6.716-15 15v107.681c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15V170H211v212h-23.84c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h137.68c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15H301V170h37.681v32.681c0 8.284 6.715 15 15 15zM286 140c-8.284 0-15 6.716-15 15v242c0 8.284 6.716 15 15 15h23.84v30H202.16v-30H226c8.284 0 15-6.716 15-15V155c0-8.284-6.716-15-15-15h-67.681c-8.284 0-15 6.716-15 15v32.681h-30V110H398.68v77.681h-30V155c0-8.284-6.716-15-15-15zM15 114.235c8.284 0 15-6.716 15-15V30h69.235c8.284 0 15-6.716 15-15s-6.716-15-15-15H15C6.716 0 0 6.716 0 15v84.235c0 8.285 6.716 15 15 15zM497 0h-84.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H482v69.235c0 8.284 6.716 15 15 15s15-6.716 15-15V15c0-8.284-6.716-15-15-15zM497 397.765c-8.284 0-15 6.716-15 15V482h-69.235c-8.284 0-15 6.716-15 15s6.716 15 15 15H497c8.284 0 15-6.716 15-15v-84.235c0-8.285-6.716-15-15-15zM15 512h84.235c8.284 0 15-6.716 15-15s-6.716-15-15-15H30v-69.235c0-8.284-6.716-15-15-15s-15 6.716-15 15V497c0 8.284 6.716 15 15 15z'></path>
    </svg>
  );
};

export default TextAreaIcon;
