import React, { useState, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignaturePad from 'react-signature-canvas';
import { BASE_URL } from '../../../../../constants';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { SignaturePadIcon } from '../../../../assets/svg-icons';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

const Signature = ({ input, inputValues, setInputValues, currentUserId }) => {
  const signatureRef = useRef(null);
  const [signature, setSignature] = useState(input.value);
  const [savedSignatures, setSavedSignatures] = useState([]);
  const [signMethod, setSignMethod] = useState(
    savedSignatures ? 'type' : 'draw'
  );
  const [redrawSignId, setRedrawSignId] = useState('');



  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/signatures?user_id=${currentUserId}`)
      .then((res) => setSavedSignatures(res.data));

    axios
    .get(`${BASE_URL}/api/v1/signatures/${input.value}?user_id=${currentUserId}`)
    .then((res) => setSignature(res.data));
    ReactTooltip.rebuild();
  }, []);

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const trimSignature = async () => {
    const signImage = signatureRef.current.isEmpty()
      ? ''
      : signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

    redrawSignId ? updateSignature(signImage) : saveSignature(signImage);
    setSignature(signImage);
  };

  const uploadSignature = () => {
    saveSignature(signature);
  };

  const saveSignature = async (userSign) => {
    const blob = await fetch(userSign).then((r) => r.blob());
    const formData = new FormData();
    formData.append('signature[signature]', blob, 'image.png');
    formData.append('signature[user_id]', currentUserId);

    userSign &&
      axios.post(`${BASE_URL}/api/v1/signatures.json`, formData).then((res) => {
        setSavedSignatures(savedSignatures.concat([res.data]));
        res.status === 200 &&
          setInputValues(
            inputValues
              .filter((ele) => ele.id !== input.id)
              .concat([{ id: input.id, value: res.data.id }])
          );
      });
  };

  const updateSignature = async (userSign) => {
    const blob = await fetch(userSign).then((r) => r.blob());
    const formData = new FormData();
    formData.append('signature[signature]', blob, 'image.png');
    formData.append('signature[user_id]', currentUserId);

    userSign &&
      axios
        .put(`${BASE_URL}/api/v1/signatures/${redrawSignId}`, formData)
        .then((res) => {
          setSavedSignatures(
            savedSignatures
              .filter((ele) => ele.id !== redrawSignId)
              .concat([res.data])
          );
          res.status === 200 &&
            setInputValues(
              inputValues
                .filter((ele) => ele.id !== input.id)
                .concat([{ id: input.id, value: res.data.id }])
            );
          setRedrawSignId('');
        });
  };

  return (
    <Popup
      trigger={
        signature ? (
          <div
            className='signature-field'
            style={{ height: '200px', width: '100%' }}
          >
            <img
              style={{ objectFit: 'scale-down' }}
              height='100%'
              width='100%'
              src={signature}
            />
          </div>
        ) : (
          <button
            className='btn signature-field'
            style={{
              height: '200px',
              width: '100%',
              fontSize: 10,
              padding: 0,
            }}
          >
            Sign Here
          </button>
        )
      }
      modal
    >
      {(close) => (
        <div style={{ padding: '0px 50px 20px 50px' }}>
          <div style={{ display: 'flex', padding: '20px 0px' }}>
            <SignaturePadIcon />
            <span style={{ color: '#47506B', fontSize: 25 }}>
              Add Signature
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px 0px',
            }}
          >
            <button
              style={{ width: '50%' }}
              className={
                signMethod === 'type'
                  ? 'btn btn-primary shadow-none'
                  : 'btn btn-outline-primary shadow-none'
              }
              onClick={() => setSignMethod('type')}
            >
              Signatures
            </button>
            <button
              style={{ width: '50%' }}
              className={
                signMethod === 'upload'
                  ? 'btn btn-primary shadow-none'
                  : 'btn btn-outline-primary shadow-none'
              }
              onClick={() => setSignMethod('upload')}
            >
              Upload
            </button>
          </div>
          {signMethod === 'draw' && (
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: '#BDBDBD',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <SignaturePad
                ref={signatureRef}
                penColor='black'
                canvasProps={{ width: 1000, height: 200 }}
              />
              <div
                style={{ position: 'absolute', bottom: 0, right: 10 }}
                onClick={() => clearSignature()}
              >
                <span>
                  <i
                    className='fa fa-refresh'
                    aria-hidden='true'
                    style={{ fontSize: 12 }}
                  ></i>
                  Reset
                </span>
              </div>
            </div>
          )}
          {signMethod === 'type' && (
            <div
              style={{
                width: '100%',
                height: 200,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {savedSignatures &&
                savedSignatures.map((sign) => (
                  <div
                    key={sign.id}
                    style={{
                      height: 100,
                      width: 100,
                      borderWidth: 1,
                      borderColor: '#BDBDBD',
                      borderStyle: 'solid',
                      margin: 5,
                    }}
                    onClick={() => {
                      setSignature(sign.signature_url);
                      setInputValues(
                        inputValues
                          .filter((ele) => ele.id !== input.id)
                          .concat([{ id: input.id, value: sign.id }])
                      );
                      close();
                    }}
                  >
                    <img
                      style={{ objectFit: 'scale-down' }}
                      height='100%'
                      width='100%'
                      src={sign.signature_url}
                    />
                    <label
                      style={{
                        fontSize: 12,
                        marginLeft: 5,
                        cursor: 'pointer',
                        color: '#374176',
                      }}
                      onClick={() => {
                        setSignMethod('draw');
                        setRedrawSignId(sign.id);
                      }}
                    >
                      Redraw
                    </label>
                  </div>
                ))}
              <div
                style={{
                  height: 100,
                  width: 100,
                  borderWidth: 1,
                  borderColor: '#BDBDBD',
                  borderStyle: 'dashed',
                  margin: 5,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setSignMethod('draw');
                }}
              >
                <label
                  style={{
                    fontSize: 50,
                    marginBottom: 0,
                    cursor: 'pointer',
                  }}
                >
                  +
                </label>
              </div>
            </div>
          )}
          {signMethod === 'upload' && (
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: '#BDBDBD',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <label
                style={{
                  width: '100%',
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {!signature && 'Click to browse'}
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => {
                    setSignature(URL.createObjectURL(e.target.files[0]));
                  }}
                  hidden
                />
                {signature && (
                  <img
                    style={{
                      cursor: 'pointer',
                      objectFit: 'scale-down',
                    }}
                    height='100%'
                    src={signature}
                  />
                )}
              </label>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <button
              style={{ width: '33%', marginRight: 5 }}
              className='btn btn-outline-primary'
              onClick={() => close()}
            >
              Cancel
            </button>
            <button
              style={{ width: '33%', marginLeft: 5 }}
              className='btn btn-primary'
              onClick={() => {
                signMethod === 'upload' && uploadSignature();
                signMethod === 'draw' && trimSignature();
                close();
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default Signature;
