import React from 'react';

const CompanyNameIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 409.294 409.294'
      fill={inputType == 'company_name_input' ? '#374176' : '#6b6b6b'}
    >
      <path d='M116.915 263.118h175.412l28.516 58.471h64.407L236.819 0h-64.398L23.991 321.588h64.407zM204.62 69.762l62.252 134.885H142.369zM0 350.824h409.294v58.471H0z'></path>
    </svg>
  );
};

export default CompanyNameIcon;
