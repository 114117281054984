import React, { useState, useEffect } from 'react';
const pdfjsLib = require('pdfjs-dist/es5/build/pdf.js');
const pdfjsWorker = require('pdfjs-dist/es5/build/pdf.worker.entry');
import ReactTooltip from 'react-tooltip';
import Page from './Page';
import { BASE_URL } from '../../../constants';
import axios from 'axios';
import {
  TextInputIcon,
  TextAreaIcon,
  SignatureIcon,
  CheckBoxIcon,
  DateIcon,
  CompanyNameIcon,
  CompanyLogoIcon,
  AcknowledgeIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '../../assets/svg-icons';
import Name from '../../assets/svg-icons/Name';
import Phone from '../../assets/svg-icons/Phone';
import Abn from '../../assets/svg-icons/Abn';
import Address from '../../assets/svg-icons/Abn';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default (props) => {
  const [pdf, setPdf] = useState(null);
  const [pages, setPages] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);
  const [inputType, setInputType] = useState('none');
  const [employeeDocData, setEmployeeDocData] = useState('');
  const [inputs, setInputs] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [staticInputs, setStaticInputs] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [scale, setScale] = useState(1.5);

  const fileUrl = document.getElementById('document-viewer').dataset.fileUrl;
  const docType = document.getElementById('document-viewer').dataset.docType;
  const canAnyoneSubmit =
    document.getElementById('document-viewer').dataset.canAnyoneSubmit;
  const toolbarStatus =
    document.getElementById('document-viewer').dataset.toolbar;
  const documentId = document.getElementById('document-viewer').dataset.docId;
  const assignmentId =
    document.getElementById('document-viewer').dataset.assignmentId;

  const currentUser =
    document.getElementById('document-viewer').dataset.currentUser;
  const userRole = document.getElementById('document-viewer').dataset.userRole;
  const docSubmissionId =
    document.getElementById('document-viewer').dataset.docSubmissionId;

  const employeeAssignmentId =
    document.getElementById('document-viewer').dataset.employeeAssignmentId;

  const submissionAt =
    document.getElementById('document-viewer').dataset.submissionAt;

  const currentUserId =
    document.getElementById('document-viewer').dataset.currentUserId;
  const docStatus =
    document.getElementById('document-viewer').dataset.docStatus;
  const canWorkerSign =
    document.getElementById('document-viewer').dataset.canWorkerSign;
  const canWorkerAcknowledge =
    document.getElementById('document-viewer').dataset.canWorkerAcknowledge;

  const processing =
    document.getElementById('document-viewer').dataset.processing;

  const isSubmitBtnEnabled = () => {
    return canAnyoneSubmit == 'true' || userRole === 'worker';
  };

  const isActionBarEnabled = () => {
    return (
      currentUser !== 'HeadOfficeOwner' &&
      currentUser !== 'StaffMember' &&
      docType === 'CompanyDocument' &&
      !submissionAt
    );
  };

  useEffect(() => {
    if (processing == 'true') {
      setTimeout(() => {
        window.location = window.location;
      }, 10000);
    }
    const fetchTemplateFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/doc_templates/${documentId}/input_fields.json`)
        .then((res) => setInputs(res.data));
    };

    const fetchTemplateStaticFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/template_static_fields/${documentId}.json`)
        .then((res) => {
          setStaticInputs(res.data);
          setIsLoading(true);
        });
    };

    const fetchCompanyDocFields = async () => {
      await axios
        .get(
          `${BASE_URL}/api/v1/company_documents/${documentId}/input_fields.json`
        )
        .then((res) => {
          let fields = [];
          if (Array.isArray(res.data)) {
            setInputs(res.data);
            res.data.map((field) => {
              field.value &&
                field.input_type !== 'signature' &&
                fields.push({
                  id: field.id,
                  value: field.value,
                  font_size: field.metadata.font_size,
                });
            });
          }
          setInputValues(fields);
          setIsLoading(true);
        });
    };

    const fetchAssignmentFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/assignments/${assignmentId}/input_fields.json`)
        .then((res) => {
          let fields = [];
          if (Array.isArray(res.data)) {
            setInputs(res.data);
            res.data.map((field) => {
              field.value &&
                fields.push({
                  id: field.id,
                  value: field.value,
                  font_size: field.metadata.font_size,
                });
            });
          }
          setInputValues(fields);
          setIsLoading(true);
        });
    };

    const fetchDocSubmissionFields = async () => {
      await axios
        .get(
          `${BASE_URL}/api/v1/doc_submissions/${docSubmissionId}/input_fields.json`
        )
        .then((res) => {
          let fields = [];
          if (Array.isArray(res.data)) {
            setInputs(res.data);
            res.data.map((field) => {
              field.value &&
                field.input_type !== 'signature' &&
                fields.push({
                  id: field.id,
                  value: field.value,
                  font_size: field.metadata.font_size,
                });
            });
          }
          setInputValues(fields);
          setIsLoading(true);
        });
    };

    docType === 'TemplateDocument' &&
      fetchTemplateFields() &&
      fetchTemplateStaticFields();

    if (docType === 'CompanyDocument') {
      docSubmissionId
        ? fetchDocSubmissionFields()
        : assignmentId
        ? fetchAssignmentFields()
        : fetchCompanyDocFields();
    }

    if (docType === 'DocSubmission') {
      if (processing == 'false') {
        setIsLoading(true);
      } else if (processing == 'true') {
        setIsLoading(false);
      }
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    try {
      pdfjsLib.getDocument(fileUrl).promise.then((pdf) => {
        setPdf(pdf);
        const myArr = Array.from({ length: pdf.numPages }, (v, i) => i + 1);
        setPages(myArr);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSaveAndAssign = () => {
    userRole === 'worker' &&
      (docSubmissionId
        ? axios
            .put(`${BASE_URL}/api/v1/doc_submissions/${docSubmissionId}.json`, {
              doc_submission_fields: inputValues,
              assignment_id: assignmentId,
              employee_assignment_id: employeeAssignmentId,
            })
            .then((res) => {
              window.location = window.location;
            })
        : axios
            .post(`${BASE_URL}/api/v1/doc_submissions.json`, {
              doc_submission_fields: inputValues,
              assignment_id: assignmentId,
              action_type: 'save',
              employee_assignment_id: employeeAssignmentId,
            })
            .then((res) => {
              window.location = window.location;
            }));

    (userRole === 'manager' || userRole === '') &&
      (assignmentId
        ? axios
            .put(`${BASE_URL}/api/v1/assignments/${assignmentId}.json`, {
              company_document_id: documentId,
              assignment_fields: inputValues,
            })
            .then((res) => {
              window.location = window.location;
            })
        : axios
            .post(`${BASE_URL}/api/v1/assignments.json`, {
              company_document_id: documentId,
              assignment_fields: inputValues,
            })
            .then((res) => {
              window.location = window.location;
            }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitDisable(false);

    docSubmissionId
      ? axios
          .put(
            `${BASE_URL}/api/v1/doc_submissions/${docSubmissionId}/submit.json`,
            {
              doc_submission_fields: inputValues,
              assignment_id: assignmentId,
              employee_assignment_id: employeeAssignmentId,
            }
          )
          .then((res) => {
            window.location = `/doc_submissions/${res.data.id}`;
          })
      : assignmentId
      ? axios
          .post(`${BASE_URL}/api/v1/doc_submissions.json`, {
            doc_submission_fields: inputValues,
            assignment_id: assignmentId,
            action_type: 'submit',
            employee_assignment_id: employeeAssignmentId,
            company_document_id: documentId,
          })
          .then((res) => {
            window.location = `/doc_submissions/${res.data.id}`;
          })
      : axios
          .post(`${BASE_URL}/api/v1/doc_submissions.json`, {
            doc_submission_fields: inputValues,
            action_type: 'submit',
            can_anyone_submit: canAnyoneSubmit,
            company_document_id: documentId,
          })
          .then((res) => {
            window.location = `/doc_submissions/${res.data.id}`;
          });
  };

  return (
    <div>
      <div
        style={{
          display: pageRendering && isLoading ? 'none' : 'flex',
          height: 'calc(100vh - 81px - 60px - 80px - 50px)',
        }}
        className='flex-column justify-content-center align-items-center'
      >
        <div className='d-flex justify-content-center'>
          <div className='spinner-border text-primary' role='status'></div>
        </div>
        {processing == 'true' && (
          <span style={{ paddingTop: '10px' }}>
            Document is submitted and being processed. The processing will be
            finished shortly.
            <a
              style={{
                color: '#374176',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => window.location.reload(false)}
            >
              {' '}
              Reload{' '}
            </a>
            the page to see the document
          </span>
        )}
      </div>
      {pdf && pages && isLoading && (
        <div style={{ paddingTop: '0px' }}>
          <div
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 5,
              marginBottom: 5,
              background: '#F3F3F3',
            }}
          >
            {(docType === 'TemplateDocument' ||
              (currentUser === 'HeadOfficeOwner' &&
                docType === 'CompanyDocument')) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  data-tip='Add Text Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('text')}
                >
                  <TextInputIcon inputType={inputType} />
                  <label className='icon-label'>Text</label>
                </div>
                <div
                  data-tip='Add TextArea Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('textarea')}
                >
                  <TextAreaIcon inputType={inputType} />
                  <label className='icon-label'>Text Area</label>
                </div>
                <div
                  data-tip='Add CheckBox'
                  className='top-bar-btn'
                  onClick={() => setInputType('checkbox')}
                >
                  <CheckBoxIcon inputType={inputType} />
                  <label className='icon-label'>Checkbox</label>
                </div>
                <div
                  data-tip='Add Acknowledge Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('acknowledge')}
                >
                  <AcknowledgeIcon inputType={inputType} />
                  <label className='icon-label'>Ack</label>
                </div>
                <div
                  data-tip='Add Signature Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('signature')}
                >
                  <SignatureIcon inputType={inputType} />
                  <label className='icon-label'>sign</label>
                </div>
                <div
                  data-tip='Add Date Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('date')}
                >
                  <DateIcon inputType={inputType} />
                  <label className='icon-label'>date</label>
                </div>
                <div
                  data-tip='Add Business Name Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('company_name_input')}
                >
                  <CompanyNameIcon inputType={inputType} />
                  <label className='icon-label'>Business Name</label>
                </div>
                <div
                  data-tip='Add Business Logo Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('logo_input')}
                >
                  <CompanyLogoIcon inputType={inputType} />
                  <label className='icon-label'>Business Logo</label>
                </div>
                <div
                  data-tip='Add Name Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('name')}
                >
                  <Name inputType={inputType} />
                  <label className='icon-label'>contact name</label>
                </div>
                <div
                  data-tip='Add Abn Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('abn')}
                >
                  <Abn inputType={inputType} />
                  <label className='icon-label'>abn</label>
                </div>
                <div
                  data-tip='Add Phone Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('phone')}
                >
                  <Phone inputType={inputType} />
                  <label className='icon-label'>phone</label>
                </div>
                <div
                  data-tip='Add Address Input'
                  className='top-bar-btn'
                  onClick={() => setInputType('address')}
                >
                  <Address inputType={inputType} />
                  <label className='icon-label'>Address</label>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <div
                data-tip='Zoom Out'
                className='top-bar-btn'
                onClick={() => scale > 0.5 && setScale(scale - 0.25)}
              >
                <ZoomOutIcon />
              </div>
              <div>
                <span>{scale * 100}%</span>
              </div>
              <div
                data-tip='Zoom In'
                className='top-bar-btn'
                onClick={() => scale < 4 && setScale(scale + 0.25)}
              >
                <ZoomInIcon />
              </div>
            </div>
            <ReactTooltip place='top' type='dark' effect='float' />
          </div>
          <div
            style={{
              overflow: 'auto',
              height:
                currentUser !== 'HeadOfficeOwner' &&
                docType === 'CompanyDocument' &&
                (docStatus === 'need_attention' ||
                  docStatus === '' ||
                  docStatus === 'assigned') &&
                !submissionAt
                  ? 'calc(100vh - 246px)'
                  : 'calc(100vh - 187px)',
            }}
          >
            {pages.map((i) => (
              <Page
                key={i}
                index={i}
                pdf={pdf}
                pgRendering={pageRendering}
                setPgRendering={setPageRendering}
                inputType={inputType}
                inputList={inputs.filter((input) => input.page_no === i)}
                staticInputList={staticInputs.filter(
                  (input) => input.page_no === i
                )}
                documentId={documentId}
                currentUser={currentUser}
                currentUserId={currentUserId}
                inputValues={inputValues}
                setInputValues={setInputValues}
                editFields={
                  docType === 'CompanyDocument' ||
                  docType === 'TemplateDocument'
                    ? false
                    : employeeDocData.can_edit_fields === null
                    ? false
                    : !employeeDocData.can_edit_fields
                }
                scale={scale}
              />
            ))}
          </div>
          {isActionBarEnabled() && (
            <div
              style={{
                position: 'sticky',
                bottom: 0,
                zIndex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
                background: '#D9D9D9',
                maxWidth: 800,
                margin: '0px auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {isSubmitBtnEnabled && (
                  <>
                    <input
                      type='checkbox'
                      id='ack-check'
                      checked={submitDisable}
                      onClick={() => setSubmitDisable(!submitDisable)}
                    />
                    <label
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setSubmitDisable(!submitDisable)}
                    >
                      I know, understand, and accept the contents of this
                      document.
                    </label>
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {(canAnyoneSubmit == '' || canAnyoneSubmit == false) &&
                  (canWorkerSign === 'true' ||
                    canWorkerAcknowledge === 'true' ||
                    userRole === 'worker') && (
                    <button
                      style={{ marginRight: 10 }}
                      className='btn btn-primary'
                      onClick={handleSaveAndAssign}
                    >
                      Save Draft
                    </button>
                  )}
                {isSubmitBtnEnabled && (
                  <div>
                    <button
                      disabled={!submitDisable}
                      className='btn btn-primary'
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {isSubmitting && (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
