import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../constants';
import axios from 'axios';
import moment from 'moment-timezone';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default (props) => {
  const { docType, docId } = props;
  const [formData, setFormData] = useState([]);
  const [formColumns, setFormColumns] = useState([]);

  useEffect(() => {
    const fetchTemplateFormsFields = async () => {
      const url = `${BASE_URL}/api/v1/company_documents/${docId}/document_form_submissions`;
      await axios.get(url).then((res) => setFormData(res.data));
    };
    const fetchDocSubmissionFormsFields = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/doc_submissions/${docId}/doc_form_fields`)
        .then((res) => setFormData(res.data));
    };
    const fetchCompanyFormsSchema = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/company_documents/${docId}/doc_form_fields`)
        .then((res) => setFormColumns(res.data));
    };
    const fetchDocFormSubmissions = async () => {
      const url = `${BASE_URL}/api/v1/company_documents/${docId}/document_form_submissions`;
      await axios.get(url).then((res) => setFormData(res.data));
    };

    docType === 'TemplateForm' && fetchTemplateFormsFields();
    docType === 'DocSubmissionForm' && fetchDocSubmissionFormsFields();
    if (docType === 'CompanyForm') {
      fetchCompanyFormsSchema();
      fetchDocFormSubmissions();
    }
  }, []);

  return (
    <div className='container-fluid table-container'>
      <table>
        <thead>
          <tr>
            {formColumns.map((column, i) => (
              <th>{column.field_name}</th>
            ))}
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {row['input_fields']
                .sort((a, b) => a.position - b.position)
                .map((f, i) => (
                  <td key={`column-${i}`}>
                    {f.field_type === 'date' ? (
                      <div className='checkbox'>
                        <div>{f.value && moment(f.value).format('DD MMMM YYYY')}</div>
                      </div>
                    ) : f.field_type === 'checkbox' ? (
                      <div className='checkbox'>
                        <input
                          type='checkbox'
                          checked={f.value}
                          readOnly={true}
                        />
                        <label></label>
                      </div>
                    ) : f.field_type === 'signature' ? (
                      <img
                        style={{ objectFit: 'scale-down' }}
                        height='100%'
                        width='100px'
                        src={f.value}
                      />
                    ) : (
                      <div>{f.value}</div>
                    )}
                  </td>
                ))}
              <td>
                <a href={`/company_documents/${docId}/submissions/${row.id}`}>
                  See details
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
