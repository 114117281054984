import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sideBarPanel'];

  connect() {}
  
  close(e) {
    e.preventDefault();
    this.sideBarPanelTarget.style.display = 'none';
    document.getElementById("menuButton").style.display="block"
    document.getElementById("main-body").style.marginLeft="0px"
    document.getElementById("footerPosition").style.marginLeft="0px"
  }

  open() {
    document.getElementById("leftnav").style.display="block"
    document.getElementById("menuButton").style.display="none"
    document.getElementById("footerPosition").style.marginLeft="210px"
  }

 

}