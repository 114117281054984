import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../constants';
import { TextInput, CheckboxInput, TextArea, DateInput } from '../forms/inputs';
import axios from 'axios';
import moment from 'moment-timezone';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default ({ docType, docId, submissionId, currentUserId, currentUser }) => {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const fetchDocFormSubmissionFields = async () => {
      const url = `${BASE_URL}/api/v1/company_documents/${docId}/document_form_submissions/${submissionId}`;
      await axios.get(url).then((res) => {
        setFormData(res.data.input_fields)
      });
    };

    if (docType === 'CompanyForm') {
      fetchDocFormSubmissionFields();
    }
  }, []);

  return (
    <div className='container form-container'>
      {formData &&
        formData
        .sort((a, b) => a.position - b.position)
        .map((input, i) => (
          <div key={i} className='input-row-container'>
            {input.field_type === 'section' ? (
              <div className="section-heading-container">
                <div className="section-heading">
                  {input.field_name}
                </div>
              </div>
            ) : (
              <>
                <div className='input-label-container'>
                  <span className="name">{input.field_name}:</span>
                  <span className="description">{input.description}</span>
                </div>
                <div className='input-container'>
                  {input.field_type === 'text' || input.field_type === 'textarea' ? (
                    <div className="value">
                      {input.value}
                    </div>
                  ) : input.field_type === 'date' ? (
                    <div className="value">
                      {input.value && moment(input.value).format('DD MMMM YYYY')}
                    </div>
                  ) : input.field_type === 'checkbox' ? (
                    <CheckboxInput
                      input={input}
                    />
                  ) : input.field_type === 'signature' ? (
                    <div
                      style={{
                        backgroundImage: `url(\"${input.value}\")`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: '80px'
                      }}
                    ></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>)}
          </div>
        ))}
        <div className='input-row-container justify-content-end p-4'>
          <button className='btn btn-primary'>
            <a href={`/company_documents/${docId}/submissions/${submissionId}/edit`}>
              <span className='text-white'>Edit</span>
            </a>
          </button>
        </div>
    </div>
  );
};
