import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'form'
  ];

  connect() {}

  submit(e) {
    e.preventDefault();
    axios
    .post(`/api/v1/doc_authorizations`, {
      doc_authorization : {
        employee_id: this.formTarget.elements.doc_authorization_employee_id.value,
        authorizable_type: this.formTarget.elements.doc_authorization_authorizable_type.value,
        authorizable_id: this.formTarget.elements.doc_authorization_authorizable_id.value,
      }
    }).then(() => {
      window.location = window.location;
    });
  }
}
