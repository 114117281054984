import React from 'react';

const CompanyLogoIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      x='0'
      y='0'
      enableBackground='new 0 0 15 15'
      version='1.1'
      viewBox='0 0 477.867 477.867'
      xmlSpace='preserve'
      fill={inputType == 'logo_input' ? '#374176' : '#6b6b6b'}
    >
      <path d='M426.667 68.267H51.2c-28.277 0-51.2 22.923-51.2 51.2V358.4c0 28.277 22.923 51.2 51.2 51.2h375.467c28.277 0 51.2-22.923 51.2-51.2V119.467c0-28.277-22.923-51.2-51.2-51.2zm17.066 197.734l-107.4-107.4c-6.664-6.663-17.468-6.663-24.132 0L170.667 300.134l-56.201-56.201c-6.664-6.663-17.468-6.663-24.132 0l-56.201 56.201V119.467c0-9.426 7.641-17.067 17.067-17.067h375.467c9.426 0 17.067 7.641 17.067 17.067v146.534z'></path>
      <circle cx='153.6' cy='187.733' r='51.2'></circle>
    </svg>
  );
};

export default CompanyLogoIcon;
