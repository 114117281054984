import React from "react";
import DocumentViewer from "./components/document-viewer";
import HtmlFormViewer from "./components/html-form-viewer";
import "../../stylesheets/application";

export default (props) => {
  return (
    <div>
      {document.getElementById("document-viewer") && <DocumentViewer />}
      {document.getElementById("html-form-viewer") && <HtmlFormViewer />}
    </div>
  );
};
