import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

$(document).on('turbolinks:load', function () {
  const MOUNT_NODE_DOCUMENT = document.getElementById('document-viewer');
  const MOUNT_NODE_FORM = document.getElementById('html-form-viewer');
  if (MOUNT_NODE_DOCUMENT) {
    ReactDOM.render(<App />, MOUNT_NODE_DOCUMENT);
  } else if (MOUNT_NODE_FORM) {
    ReactDOM.render(<App />, MOUNT_NODE_FORM);
  }
});
