import React, { useState, useEffect, useRef } from 'react';
import {
  AbnInput,
  AcknowledgedInput,
  AddressInput,
  NameInput,
  PhoneInput,
  TextInput,
  TextArea,
  Signature,
  DateInput,
  LogoInput,
  CompanyNameInput,
  Checkbox,
} from '../input-types';
import { BASE_URL } from '../../../constants';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

const Page = ({
  index,
  pdf,
  pgRendering,
  setPgRendering,
  inputType,
  inputList,
  staticInputList,
  documentId,
  currentUser,
  currentUserId,
  inputValues,
  setInputValues,
  editFields,
  scale,
}) => {
  const pageRef = useRef(null);
  const inputsLayerRef = useRef(null);
  const PageContainerRef = useRef(null);
  const [mousedown, setMouseDown] = useState(false);
  const [mousedownX, setMouseDownX] = useState(0);
  const [mousedownY, setMouseDownY] = useState(0);
  const [inputs, setInputs] = useState(inputList);
  const [staticInputs, setStaticInputs] = useState(staticInputList);
  const [acknowledgeLabel, setAcknowledgeLabel] = useState('Acknowledge');
  const docType = document.getElementById('document-viewer').dataset.docType;
  const companyDocId = document.getElementById('document-viewer').dataset
    .companyDocId;

  const assignmentId = document.getElementById('document-viewer').dataset
    .assignmentId;

  useEffect(() => {
    pdf.getPage(index).then((page) => {
      const view = page.getViewport({
        scale: scale,
      });
      const canvas = pageRef.current;
      const overCanvas = inputsLayerRef.current;
      const divPage = PageContainerRef.current;
      const ctx = canvas.getContext('2d');

      canvas.height = view.height;
      canvas.width = view.width;

      overCanvas.height = view.height;
      overCanvas.width = view.width;

      divPage.style.height = `${view.height}px`;
      divPage.style.width = `${view.width}px`;

      const renderContext = {
        canvasContext: ctx,
        viewport: view,
      };

      // TODO Text Layer of PDF
      // page.getTextContent().then(text=>console.log(text));

      const renderTask = page.render(renderContext);
      renderTask.promise.then(() => setPgRendering(true));
    });
  }, [scale]);

  const getMousePos = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
      page: index,
    };
  };

  const addInput = (x, y) => {
    // console.log(mousedownX, mousedownY, x - mousedownX, y - mousedownY, index);

    x - mousedownX >= 5 && y - mousedownY >= 5 && inputType !== 'none'
      ? inputType === 'logo_input' || inputType === 'company_name_input' || inputType === 'name' || inputType === 'phone' || inputType === 'abn' || inputType ==='address'
        ? x - mousedownX >= 15 &&
          y - mousedownY >= 10 &&
          setStaticInputs(
            staticInputs.concat([
              {
                id: 'temp_' + new Date().getTime(),
                x_point: mousedownX,
                y_point: mousedownY,
                height: y - mousedownY,
                width: x - mousedownX,
                static_input_type: inputType,
                page_no: index,
                color: '#f4ec3270',
                metadata: {},
              },
            ])
          )
        : inputType === 'checkbox'
        ? setInputs(
            inputs.concat([
              {
                id: 'temp_' + new Date().getTime(),
                x_point: mousedownX,
                y_point: mousedownY,
                height: y - mousedownY,
                width: x - mousedownX,
                input_type: inputType,
                page_no: index,
                required: false,
                color: '#f4ec3270',
              },
            ])
          )
        : inputType === 'acknowledge'
        ? setInputs(
            inputs.concat([
              {
                id: 'temp_' + new Date().getTime(),
                x_point: mousedownX,
                y_point: mousedownY,
                height: y - mousedownY,
                width: x - mousedownX,
                input_type: inputType,
                page_no: index,
                required: false,
                color: '#f4ec3270',
                metadata: {
                  label: acknowledgeLabel,
                },
              },
            ])
          )
        : x - mousedownX >= 15 &&
          y - mousedownY >= 10 &&
          setInputs(
            inputs.concat([
              {
                id: 'temp_' + new Date().getTime(),
                x_point: mousedownX,
                y_point: mousedownY,
                height: y - mousedownY,
                width: x - mousedownX,
                input_type: inputType,
                page_no: index,
                required: false,
                color: '#f4ec3270',
                metadata: {},
              },
            ])
          )
      : null;
  };

  const setRequired = (checked1, id) => {
    const input = inputs.find((input) => input.id === id);
    setInputs(
      inputs
        .filter((element) => !(element.id === id))
        .concat([
          {
            id: 'temp_' + new Date().getTime(),
            x_point: input.x_point,
            y_point: input.y_point,
            height: input.height,
            width: input.width,
            input_type: input.input_type,
            page_no: input.page_no,
            color: '#f4ec3270',
            required: false,
            metadata:
              input.input_type === 'acknowledge'
                ? {
                    label: input.metadata ? input.metadata.label : '',
                  }
                : input.input_type === 'text' ||
                  input.input_type === 'textarea' ||
                  input.input_type === 'date'
                ? {
                    font_size:
                      (input.metadata && input.metadata.font_size) || 13,
                  }
                : {},
          },
        ])
    );
  };

  const handleStop = (input, x, y, id) => {
    setInputs(
      inputs
        .filter((element) => !(element.id === input.id))
        .concat([
          {
            id: 'temp_' + new Date().getTime(),
            x_point: input.x_point + x,
            y_point: input.y_point + y,
            height: input.height,
            width: input.width,
            input_type: input.input_type,
            page_no: input.page_no,
            required: false,
            color: input.color,
            metadata:
              input.input_type === 'acknowledge'
                ? {
                    label: input.metadata ? input.metadata.label : '',
                  }
                : input.input_type === 'text' ||
                  input.input_type === 'textarea' ||
                  input.input_type === 'date'
                ? {
                    font_size:
                      (input.metadata && input.metadata.font_size) || 13,
                  }
                : {},
          },
        ])
    );
  };

  const handleStopStaticInput = (input, x, y, id) => {
    setStaticInputs(
      staticInputs
        .filter((element) => !(element.id === input.id))
        .concat([
          {
            x_point: input.x_point + x,
            y_point: input.y_point + y,
            height: input.height,
            width: input.width,
            static_input_type: input.static_input_type,
            page_no: input.page_no,
            color: input.color,
            metadata: {
              font_size: (input.metadata && input.metadata.font_size) || 13,
            },
          },
        ])
    );
  };

  const saveInput = (id, fontSize, tooltip) => {
    const input = inputs.find((input) => input.id === id);
    const data = {
      page_no: input.page_no,
      input_type: input.input_type,
      x_point: input.x_point / scale,
      y_point: input.y_point / scale,
      height: input.height / scale,
      width: input.width / scale,
      required: false,
      metadata:
        input.input_type === 'acknowledge'
          ? {
              label: acknowledgeLabel,
            }
          : input.input_type === 'text' ||
            input.input_type === 'textarea' ||
            input.input_type === 'date'
          ? {
              font_size: fontSize && fontSize,
              label: tooltip && tooltip,
            }
          : input.input_type === 'signature'
          ? {
              label: tooltip && tooltip,
            }
          : {},
    };
    docType === 'TemplateDocument' &&
      axios
        .post(
          `${BASE_URL}/api/v1/doc_templates/${documentId}/input_fields`,
          data
        )
        .then((res) => {
          setInputs(
            inputs.filter((element) => !(element.id === id)).concat([res.data])
          );
          setAcknowledgeLabel('Acknowledge');
        });
    docType === 'CompanyDocument' && !assignmentId;
    axios
      .post(
        `${BASE_URL}/api/v1/company_documents/${documentId}/input_fields`,
        data
      )
      .then((res) => {
        setInputs(
          inputs.filter((element) => !(element.id === id)).concat([res.data])
        );
        setAcknowledgeLabel('Acknowledge');
      });
  };

  const deleteInput = (input) => {
    !input.color
      ? axios
          .delete(
            `${BASE_URL}/api/v1/doc_templates/${input.inputable_id}/input_fields/${input.id}`
          )
          .then(
            (res) =>
              res.data.success &&
              setInputs(inputs.filter((element) => !(element.id === input.id)))
          )
      : setInputs(inputs.filter((element) => !(element.id === input.id)));
  };

  const saveStaticInput = (id, fontSize) => {
    const input = staticInputs.find((input) => input.id === id);
    axios
      .post(`${BASE_URL}/api/v1/template_static_fields.json`, {
        doc_template_id: documentId,
        page_no: input.page_no,
        static_input_type: input.static_input_type,
        x_point: input.x_point / scale,
        y_point: input.y_point / scale,
        height: input.height / scale,
        width: input.width / scale,
        metadata:
          input.static_input_type != 'logo_input'
            ? {
                font_size: fontSize,
              }
            : {},
      })
      .then((res) => {
        setStaticInputs(
          staticInputs
            .filter((element) => !(element.id === id))
            .concat([res.data])
        );
      });
  };

  const deleteStaticInput = (input) => {
    !input.color
      ? axios
          .delete(
            `${BASE_URL}/api/v1/template_static_fields/${input.doc_template_id}`,
            {
              params: {
                template_static_field_id: input.id,
              },
            }
          )
          .then(
            (res) =>
              res.data.success &&
              setStaticInputs(
                staticInputs.filter((element) => !(element.id === input.id))
              )
          )
      : setStaticInputs(
          staticInputs.filter((element) => !(element.id === input.id))
        );
  };

  const MouseDown = (event) => {
    const canvas = inputsLayerRef.current;
    const mousePos = getMousePos(canvas, event);
    setMouseDownX(mousePos.x);
    setMouseDownY(mousePos.y);
    setMouseDown(true);
  };

  const MouseMove = (event) => {
    const canvas = inputsLayerRef.current;
    const ctx = canvas.getContext('2d');
    const mousePos = getMousePos(canvas, event);
    if (mousedown) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.setLineDash([6]);
      ctx.strokeRect(
        mousedownX,
        mousedownY,
        mousePos.x - mousedownX,
        mousePos.y - mousedownY
      );
    }
  };

  const MouseUp = (event) => {
    const canvas = inputsLayerRef.current;
    const ctx = canvas.getContext('2d');
    const mousePos = getMousePos(canvas, event);
    setMouseDown(false);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    addInput(mousePos.x, mousePos.y);
  };

  return (
    <div
      style={{ display: pgRendering ? 'block' : 'none' }}
      className='document-page'
    >
      <div
        ref={PageContainerRef}
        id={`page-${index}`}
        style={{ position: 'relative', margin: '0px auto' }}
      >
        <canvas
          style={{ position: 'absolute', top: 0, left: 0 }}
          ref={pageRef}
        ></canvas>
        <canvas
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'transparent',
            overflow: 'hidden',
          }}
          ref={inputsLayerRef}
          onMouseDown={inputType !== 'none' ? MouseDown : () => {}}
          onMouseMove={inputType !== 'none' ? MouseMove : () => {}}
          onMouseUp={inputType !== 'none' ? MouseUp : () => {}}
        ></canvas>
        {staticInputs &&
          staticInputs.map((input, i) =>
            input.static_input_type === 'logo_input' ? (
              <LogoInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : input.static_input_type === 'company_name_input' ? (
              <CompanyNameInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : input.static_input_type === 'name' ? (
              <NameInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : input.static_input_type === 'phone' ? (
              <PhoneInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : input.static_input_type === 'abn' ? (
              <AbnInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : input.static_input_type === 'address' ? (
              <AddressInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                onDelete={() => deleteStaticInput(input)}
                onSaveInput={saveStaticInput}
                onStopHandler={handleStopStaticInput}
                scale={scale}
              />
            ) : null
          )}
        {inputs &&
          inputs.map((input, i) =>
            input.input_type === 'text' ? (
              <TextInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                setChecked={setRequired}
                currentUser={currentUser}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                inputValues={inputValues}
                setInputValues={setInputValues}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : input.input_type === 'textarea' ? (
              <TextArea
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                setChecked={setRequired}
                currentUser={currentUser}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                inputValues={inputValues}
                setInputValues={setInputValues}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : input.input_type === 'checkbox' ? (
              <Checkbox
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                setChecked={setRequired}
                currentUser={currentUser}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                inputValues={inputValues}
                setInputValues={setInputValues}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : input.input_type === 'acknowledge' ? (
              <AcknowledgedInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808050'}
                setChecked={setRequired}
                currentUser={currentUser}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                inputValues={inputValues}
                setInputValues={setInputValues}
                setAcknowledgeLabel={setAcknowledgeLabel}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : input.input_type === 'signature' ? (
              <Signature
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                currentUser={currentUser}
                currentUserId={currentUserId}
                setChecked={setRequired}
                inputValues={inputValues}
                setInputValues={setInputValues}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : input.input_type === 'date' ? (
              <DateInput
                key={i}
                id={i}
                input={input}
                color={input.color ? input.color : '#80808070'}
                setChecked={setRequired}
                currentUser={currentUser}
                onDelete={() => deleteInput(input)}
                onSaveInput={saveInput}
                inputValues={inputValues}
                setInputValues={setInputValues}
                editFields={editFields}
                onStopHandler={handleStop}
                scale={scale}
              />
            ) : null
          )}
      </div>
      {/* {(fileName==="A2.1"&&index===1)&&<input name="DW-Area"  style={{width: 309, height: 35, padding: '0px 5px', position: 'relative', top: -198, left: 96}}></input>} */}
    </div>
  );
};

export default Page;
