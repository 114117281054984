import React from 'react';

const Name = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='15'
      width='15'
      x='0'
      y='0'
      enableBackground='new 0 0 15 15'
      version='1.1'
      viewBox='0 0 426.667 426.667'
      xmlSpace='preserve'
      fill={inputType == 'name' ? '#374176' : '#6b6b6b'}
    >
      <path d='M414.08 204.373L222.187 12.48C214.4 4.8 203.733 0 192 0H42.667C19.093 0 0 19.093 0 42.667V192c0 11.84 4.8 22.507 12.587 30.187l192 192c7.68 7.68 18.347 12.48 30.08 12.48s22.507-4.8 30.187-12.48l149.333-149.333c7.68-7.787 12.48-18.453 12.48-30.187 0-11.84-4.8-22.507-12.587-30.294zM74.667 106.667c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.294 32-32 32z'></path>
    </svg>
  );
};

export default Name;
