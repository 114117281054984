import ReactOnRails from 'react-on-rails';

import Button from './App/components/html-form-viewer/button';
import HtmlFormViewer from './App/components/html-form-viewer';
import HtmlFormTable from './App/components/html-form-viewer/table';
import FormData from './App/components/html-form-viewer/formData';
import EditForm from './App/components/html-form-viewer/edit';

ReactOnRails.register({
  Button,
  HtmlFormViewer,
  HtmlFormTable,
  FormData,
  EditForm,
})