import React from 'react';

const TextInputIcon = ({ inputType }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 467.765 467.765'
      fill={inputType == 'text' ? '#374176' : '#6b6b6b'}
    >
      <path d='M58.471 0v87.706h58.471V58.471h87.706v350.824h-58.471v58.471h175.412v-58.471h-58.471V58.471h87.706v29.235h58.471V0z'></path>
    </svg>
  );
};

export default TextInputIcon;
