import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Button = () => {
  return (
    <a href='#' class='btn btn-sm btn-primary m-3'>
      Fill the form
    </a>
  );
};

export default Button;
